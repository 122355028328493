

export const SUBSCRIPTIONS = [

    {
        id: 1,
        productName: "Hefty Small Garbage Bags Bathroom",
        deliveredEvery: 6,
        Frequency: "Months",
        quantity: 1,
        nextDelivery: "10/24/2023",
        price: 4.62,
    },
    {
        id: 2,
        productName: "Philosophy Purity Facial Cleanser",
        deliveredEvery: 6,
        Frequency: "Months",
        quantity: 1,
        nextDelivery: "11/24/2023",
        price: 36.10,
    },
    {
        id: 3,
        productName: "Amazon Basics Antibacterial Liquid Hand Soap Refill",
        deliveredEvery: 4,
        Frequency: "Months",
        quantity: 1,
        nextDelivery: "10/24/2023",
        price: 4.35,
    },
    {
        id: 4,
        productName: "One A Day Women Multivitamin Gummy",
        deliveredEvery: 5,
        Frequency: "Months",
        quantity: 2,
        nextDelivery: "10/24/2023",
        price: 17.13,
    },
    {
        id: 5,
        productName: "Hefty Ultra Strong Multipurpose Large Trash Bags kitchen",
        deliveredEvery: 4,
        Frequency: "Months",
        quantity: 1,
        nextDelivery: "10/24/2023",
        price: 15.07,
    },
    {
        id: 6,
        productName: "Bounty Quick Size Paper Towels",
        deliveredEvery: 3,
        Frequency: "Months",
        quantity: 1,
        nextDelivery: "9/24/2023",
        price: 20.76,
    },
    {
        id: 7,
        productName: "Quilted Northern Ultra Toilet Paper",
        deliveredEvery: 1,
        Frequency: "Month",
        quantity: 1,
        nextDelivery: "7/24/2023",
        price: 16.85,
    },
    {
        id: 8,
        productName: "Cottonelle Flushable Wet Wipes",
        deliveredEvery: 4,
        Frequency: "Months",
        quantity: 1,
        nextDelivery: "9/24/2023",
        price: 13.42,
    },
    {
        id: 9,
        productName: "IAMS Dry Cat Food",
        deliveredEvery: 3,
        Frequency: "Months",
        quantity: 1,
        nextDelivery: "9/24/2023",
        price: 28.78,
    },
    {
        id: 10,
        productName: "Secret Deodorant",
        deliveredEvery: 6,
        Frequency: "Months",
        quantity: 1,
        nextDelivery: "1/24/2024",
        price: 17.08,
    },
    {
        id: 11,
        productName: "Panty Liners",
        deliveredEvery: 7,
        Frequency: "Weeks",
        quantity: 1,
        nextDelivery: "7/28/2023",
        price: 6.10,
    },
    {
        id: 12,
        productName: "Paul Mitchell Hair Serum",
        deliveredEvery: 3,
        Frequency: "Months",
        quantity: 1,
        nextDelivery: "10/24/2023",
        price: 15.75,
    },
    {
        id: 13,
        productName: "Paul Mitchell Duo Set",
        deliveredEvery: 4,
        Frequency: "Months",
        quantity: 1,
        nextDelivery: "9/24/2023",
        price: 69.83,
    },
    {
        id: 14,
        productName: "Clorox Disinfecting Wipes",
        deliveredEvery: 3,
        Frequency: "Months",
        quantity: 1,
        nextDelivery: "10/24/2023",
        price: 10.02,
    },
    {
        id: 15,
        productName: "Crest 3D White Toothpaste",
        deliveredEvery: 4,
        Frequency: "Months",
        quantity: 1,
        nextDelivery: "10/24/2023",
        price: 14.24,
    },
    {
        id: 16,
        productName: "Arm & Hammer Cat Litter Deodorizer",
        deliveredEvery: 1,
        Frequency: "Month",
        quantity: 1,
        nextDelivery: "7/24/2023",
        price: 2.82,
    },
    {
        id: 17,
        productName: "Fresh Step Clumping Cat Litter",
        deliveredEvery: 1,
        Frequency: "Month",
        quantity: 1,
        nextDelivery: "7/24/2023",
        price: 9.44,
    },
    {
        id: 18,
        productName: "Orgain Organic Nutritional Protein Shake",
        deliveredEvery: 2,
        Frequency: "Weeks",
        quantity: 1,
        nextDelivery: "7/28/2023",
        price: 25.43,
    },
    {
        id: 19,
        productName: "Cascade Complete Dishwasher Pods",
        deliveredEvery: 6,
        Frequency: "Months",
        quantity: 1,
        nextDelivery: "10/24/2023",
        price: 16.95,
    },
    {
        id: 20,
        productName: "Always Radiant Feminine Pads 5",
        deliveredEvery: 2,
        Frequency: "Months",
        quantity: 1,
        nextDelivery: "8/24/2023",
        price: 20.32,
    },
    {
        id: 21,
        productName: "Always Radiant Feminine Pads 1",
        deliveredEvery: 5,
        Frequency: "Months",
        quantity: 1,
        nextDelivery: "8/24/2023",
        price: 20.32,
    },
    {
        id: 22,
        productName: "Febreze Odor-Fighting Fade Defy PLUG Air Freshener Refill",
        deliveredEvery: 4,
        Frequency: "Months",
        quantity: 1,
        nextDelivery: "9/24/2023",
        price: 11.00,
    },
]