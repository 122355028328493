import clock from "./components/img/clock.jpeg";
import robotvac from "./components/img/robotvac.jpeg";
import kittyvac from "./components/img/kittyvac.jpeg";
import cutepens from "./components/img/pens.jpeg";
import surge from "./components/img/surgebackup.jpeg";

import mac from "./components/img/macshortcuts.jpeg";
import clipboard from "./components/img/clipboard.jpeg";
import notepad from "./components/img/notepad.jpeg";
import laptapStand from "./components/img/laptopstand.jpeg";
import businessBook from "./components/img/mindbook.jpeg";

import mugWarmer from "./components/img/mugwarmer.jpeg";
import keyCap from "./components/img/keycap.jpeg";
import starKeyCap from "./components/img/starkeycap.jpeg";
import phoneStand from "./components/img/phonestand.jpeg";
import miniTrash from "./components/img/minitrash.jpeg";

import laptopBackPack from "./components/img/laptopbackpack.jpeg";
import pcSpeaker from "./components/img/computerspeaker.jpeg";
import popKitty from "./components/img/popkitty.jpeg";
import monitor from "./components/img/monitor.jpeg";
import pc from "./components/img/pc.jpeg";

import travelBackPack from "./components/img/travelbackpack.jpeg";
import appleTag from "./components/img/appletag.jpeg";
import catMouse from "./components/img/catmouse.jpeg";
import robotBlue from "./components/img/robotbluetooth.jpeg";
import mic from "./components/img/mic.jpeg";

import atomic from "./components/img/atomicbook.jpeg";
import wheel from "./components/img/wheels.jpeg";
import iphoneTV from "./components/img/iphonetvadapt.jpeg";
import petvac from "./components/img/petvac.jpeg";

import coffeeMaker from "./components/img/coffeemaker.jpeg";
import watchHolder from "./components/img/watchholder.jpeg";
import headsetHolder from "./components/img/headsetholder.jpeg";
import deskMat from "./components/img/deskmat.jpeg";
import solarCharger from "./components/img/solarcharger.jpeg";

import vrMask from "./components/img/vrmask.jpeg";
import smallKeyboard from "./components/img/smallkeyboard.jpeg";
import mechanical from "./components/img/mechanicalkeyboard.jpeg";
import monitorStand from "./components/img/monitorstand.jpeg";
import travelBag from "./components/img/travelcompressbag.jpeg";

import monitorMount from "./components/img/monitorMount.jpeg";
import vga from "./components/img/vga.jpeg";
import capPuller from "./components/img/KeycapPuller.jpeg";
import displayPort from "./components/img/DisplayPortDPtoVGA .jpeg";
import airpodHolder from "./components/img/DanceCatAirpodHolder.jpeg";

import gamingMouse from "./components/img/GamingMouseWired.jpeg";
import robotVacuum from "./components/img/RobotVacuum.jpeg";
import smartLights from "./components/img/SmartLight.jpeg";
import vLaptopStand from "./components/img/VerticalLaptopStand.jpeg";

export const PRODUCTS = [

    {
        id: 1,
        productName: "LED Mirror Clock",
        description: "The LED Mirror Clock: a gentle reminder to take a lunch break. That bug will still be there when you return, don't worry!",
        image: clock,
        webPage: "https://amzn.to/3F0EmpD"
    },
    {
        id: 2,
        productName: "Mini Robot Vacuum",
        description: "Introducing the Mini Robot Vacuum: a cute little cleaning companion that tackles crumbs on your desk and keyboard, leaving no mess behind.",
        image: robotvac,
        webPage: "https://amzn.to/3ycpalo",
    },
    {
        id: 3,
        productName: "Mini Cat Vacuum",
        description: "Say hello to the Mini Cat Vacuum: an adorable kitty-shaped cleaner that purrs its way through crumbs on your desk and keyboard.",
        image: kittyvac,
        webPage: "https://amzn.to/3IUcmFd",
    },
    {
        id: 4,
        productName: "Fancy Pens",
        description: "Get ready to solve those bugs in style with these Fancy Pens: the perfect writing companions to jot down your brilliant ideas and conquer coding challenges.",
        image: cutepens,
        webPage: "https://amzn.to/3KYpZ9l",
    },
    {
        id: 5,
        productName: "Surge Protector Battery Power Backup",
        description: "When the power goes out, you can't afford to lose your Wi-Fi and monitors! Keep your coding fortress intact with our Surge Protector Battery Power Backup.",
        image: surge,
        webPage: "https://amzn.to/3IPf6nv"
    },
    {
        id: 6,
        productName: "Mac Keyboard Shortcuts Sticker",
        description: "Avoid pressing COMMAND + C twice with a Mac Keyboard Shortcuts Sticker. It's a great reminder to keep your fingers in check while you navigate the coding universe.",
        image: mac,
        webPage: "https://amzn.to/3mrlUAj",
    },
    {
        id: 7,
        productName: "Mini Clipboards Folio",
        description: "Behold the Mini Clipboards Folio: the perfect tool for writing down those domain ideas you'll probably never use but can't resist keeping. Get ready to pay for creativity!",
        image: clipboard,
        webPage: "https://amzn.to/3KXsMiU",
    },
    {
        id: 8,
        productName: "Notepads",
        description: "Complete your mini clipboard setup with a handy Notepads. They're the perfect fit for your wild and imaginative ideas that deserve to be written down.",
        image: notepad,
        webPage: "https://amzn.to/3ZnpycZ",
    },
    {
        id: 9,
        productName: "Laptop Stand",
        description: "Get an eye-level view with a Laptop Stand, because sometimes the bug that got away needs to be seen from a higher perspective. Keep those coding mysteries in sight!",
        image: laptapStand,
        webPage: "https://amzn.to/3ZJsb8y"
    },
    {
        id: 10,
        productName: "Mind Your Business",
        description: "Uncover great ideas and stay on track with your goals using 'Mind Your Business.' It's like having a personal assistant without the paycheck!",
        image: businessBook,
        webPage: "https://amzn.to/3EYDL7Z",
    },
    {
        id: 11,
        productName: "Coffee Warmer",
        description: "When you're deep in the coding zone, don't let your coffee get cold! This Coffee Warmer keeps your java piping hot, so you can fuel your coding adventures.",
        image: mugWarmer,
        webPage: "https://amzn.to/3Jf9NPu",
    },
    {
        id: 12,
        productName: "Keycaps",
        description: "Looking for a change in your keyboard? These Key caps are here to spice things up and bring a touch of excitement to your coding sessions.",
        image: keyCap,
        webPage: "https://amzn.to/41NSV9R",
    },
    {
        id: 13,
        productName: "Star Keycap",
        description: "Need a little sparkle on your keyboard? This Star Key cap will add a touch of magic and make your coding experience shine brighter than ever.",
        image: starKeyCap,
        webPage: "https://www.amazon.com/Personalized-Transparent-Handmade-Mechanical-Keyboard/dp/B09L5ZVBMQ?crid=21W0KT391UOSP&keywords=star+keycaps&qid=1678053767&s=electronics&sprefix=star+keycaps%2Celectronics%2C126&sr=1-3&linkCode=sl1&tag=coderchic-20&linkId=93b9e1ae6def0df15a8ec15200def8fb&language=en_US&ref_=as_li_ss_tl"
    },
    {
        id: 14,
        productName: "Foldable Phone Stand for Desk",
        description: "Give your phone a seat of honor with this Foldable Phone Stand for Desk. Your loyal companion deserves a comfortable spot while you tackle coding challenges.",
        image: phoneStand,
        webPage: "https://amzn.to/3JhnU7c",
    },
    {
        id: 15,
        productName: "Mini Trash Can for Desk",
        description: "For all those candy wrappers that seem to get in the way of your coding greatness, meet our Mini Trash Can for Desk. Keep your workspace tidy and your focus sharp!",
        image: miniTrash,
        webPage: "https://amzn.to/3ZqPt3q",
    },
    {
        id: 16,
        productName: "Lunch Backpack",
        description: "Take your laptop and lunch to the park for a coding adventure. Enjoy the sunshine while hunting down that elusive bug or... more bugs!",
        image: laptopBackPack,
        webPage: "https://amzn.to/41M1ou6",
    },
    {
        id: 17,
        productName: "Laptop Speaker",
        description:  "Struggling to hear your coworkers? Why not drown them out with some music instead? This Laptop Speaker lets you rock out while coding.",
        image: pcSpeaker,
        webPage: "https://amzn.to/3IPhyud"
    },
    {
        id: 18,
        productName: "Marvel: Goose The Cat Toy",
        description: "Let this adorable kitty join your coding adventures! Because let's face it, you can't just stare at code all day. Marvel at the cuteness!",
        image: popKitty,
        webPage: "https://amzn.to/3YiNHjn",
    },
    {
        id: 19,
        productName: "Samsung Monitors",
        description: "Need to exterminate bugs swiftly? The bigger the screen, the faster they'll disappear! Arm yourself with Samsung Monitors and prepare for coding domination.",
        image: monitor,
        webPage: "https://amzn.to/3F2FIjJ",
    },
    {
        id: 20,
        productName: "Gaming PC",
        description: "When bugs and enemies stand in your way, unleash the power of Gaming! It's not just about coding; it's about conquering the virtual realms and emerging victorious.",
        image: pc,
        webPage: "https://amzn.to/3YDaNBL",
    },
    {
        id: 21,
        productName: "Travel Backpack",
        description: "Ready for a change in scenery? This Travel Backpack is here to whisk you away from coding stress. Beat that bug or simply escape the daily grind in style!",
        image: travelBackPack,
        webPage: "https://amzn.to/41KwYIS"
    },
    {
        id: 22,
        productName: "Apple AirTag",
        description: "Don't waste precious coding time searching for your keys. Let the Apple AirTag save the day and keep you focused on what truly matters—your code.",
        image: appleTag,
        webPage: "https://amzn.to/3ZmGMY1",
    },
    {
        id: 23,
        productName: "Computer Mouse",
        description: "Tired of tears and frustration in front of your computer? This Computer Mouse will be there to cheer you up and provide a comforting click. It's a shoulder to cry on, virtually speaking.",
        image: catMouse,
        webPage: "https://amzn.to/3SPjGGK",
    },
    {
        id: 24,
        productName: "Pixel Art Bluetooth",
        description: "Need an extra dose of cuteness to lift your spirits? The Pixel Art Bluetooth companion will be there to brighten your day when panic sets in. Code with a smile!",
        image: robotBlue,
        webPage: "https://amzn.to/3JfglxL",
    },
    {
        id: 25,
        productName: "Gaming USB Microphone",
        description: "Testing, testing... Can you hear me now? Ensure crystal-clear communication with this Gaming USB Microphone. No more misunderstandings or frustrating tech glitches!",
        image: mic,
        webPage: "https://amzn.to/3SQ5AFa"
    },
    {
        id: 26,
        productName: "Atomic Habits",
        description: "Discover the secret to success—good habits! Dive into \"Atomic Habits\" and learn how small changes can lead to remarkable coding achievements. Your future self will thank you.",
        image: atomic,
        webPage: "https://amzn.to/3IV9p7k",
    },
    {
        id: 27,
        productName: "Office Chair Wheels",
        description: "Burning the midnight oil at your computer? No judgment here! With our Office Chair Wheels, you can silently glide through the night like a coding ninja. Your secret is safe with us!",
        image: wheel,
        webPage: "https://amzn.to/3Jg5QKw",
    },
    {
        id: 28,
        productName: "HDMI Adapter for iPhone",
        description: "Want to see things on a bigger screen? Because sometimes squinting just won't do.",
        image: iphoneTV,
        webPage: "https://amzn.to/3yxg6bj",
    },
    {
        id: 29,
        productName: "Bissell Pet Hair Vacuum",
        description: "Don't let pet hair distract you from coding greatness. Let the vacuum handle the fur, while you handle the bugs.",
        image: petvac,
        webPage: "https://amzn.to/41OWdd6",
    },
    {
        id: 30,
        productName: "Coffee Maker",
        description: "Gotta stay hydrated... with a strong dose of caffeine. Coffee is the fuel that powers your code.",
        image: coffeeMaker,
        webPage: "https://amzn.to/3mBKRJ8"
    },
    {
        id: 31,
        productName: "2 in 1 Charging Station",
        description: "Charge your Apple Watch and AirPods Pro in one convenient spot. Because even your gadgets need a cozy home.",
        image: watchHolder,
        webPage: "https://amzn.to/3yCJmwZ",
    },
    {
        id: 32,
        productName: "RGB Headset Stand",
        description: "Keep it organized and vibrant! Your headset deserves a stylish perch that matches your coding aesthetics.",
        image: headsetHolder,
        webPage: "https://amzn.to/3LdmEmH",
    },
    {
        id: 33,
        productName: "Large Desk Mat",
        description: "Protect your desk from all those snacks that fuel your coding adventures. Crumbs have no place in your code kingdom.",
        image: deskMat,
        webPage: "https://amzn.to/3YCmrMZ",
    },
    {
        id: 34,
        productName: "Solar Charger",
        description: "When the sun shines, your phone will charge. Embrace the power of the sun to keep your coding sessions uninterrupted.",
        image: solarCharger,
        webPage: "https://amzn.to/3YzTZLK"
    },
    {
        id: 35,
        productName: "VR Sweat Band",
        description: "Sweat rolling down your eyes won't help you defeat enemies. Stay cool and focused with this VR sweat band.",
        image: vrMask,
        webPage: "https://amzn.to/3To7ldf",
    },
    {
        id: 36,
        productName: "Wireless Gaming Keyboard",
        description: "Code from anywhere and free yourself from the tangles of cables. Let your fingers dance across the keys without restraint.",
        image: smallKeyboard,
        webPage: "https://amzn.to/3l4AqNR",
    },
    {
        id: 37,
        productName: "Mechanical Keyboard",
        description: "Tell the world you're a Developer without saying a word. Elevate your coding experience with the satisfying clickety-clack of a mechanical keyboard.",
        image: mechanical,
        webPage: "https://amzn.to/424wy00",
    },
    {
        id: 38,
        productName: "Dual Monitor Stand",
        description: "Get an eye-level view to find the bug that got away. Elevate your monitors and elevate your coding prowess.",
        image: monitorStand,
        webPage: "https://amzn.to/3Jxnppz"
    },
    {
        id: 39,
        productName: "Compression Bags for Travel",
        description: "Pack as much as you want and still have room for those pesky bugs. Say goodbye to bulky luggage and hello to efficient packing.",
        image: travelBag,
        webPage: "https://amzn.to/3LhcC45",
    },
    {
        id: 40,
        productName: "Monitor Desk Mount",
        description: "Experience a better way to code with a sleek and adjustable monitor desk mount. Your screens will be at the perfect angle for bug hunting.",
        image: monitorMount,
        webPage: "https://amzn.to/3YGHpKw",
    },
    {
        id: 41,
        productName: "VGA Cable",
        description: "Connect your laptop to multiple monitors and unleash your coding potential. Embrace the power of dual screens!",
        image: vga,
        webPage: "https://amzn.to/3yv9MAL",
    },
    {
        id: 42,
        productName: "Keycap Puller",
        description: "Change your keyboard keycap with ease and add a touch of personalization to your coding kingdom. Customize to your heart's content!",
        image: capPuller,
        webPage: "https://amzn.to/3mMbF9R"
    },
    {
        id: 43,
        productName: "DisplayPort (DP) to VGA",
        description: "Perfect for your Dell dock setup. Connect with confidence and enjoy seamless display connectivity.",
        image: displayPort,
        webPage: "https://amzn.to/404BYXf",
    },
    {
        id: 44,
        productName: "Cat Airpod Holder Headphone",
        description: "Need some sass with your code? This cat-themed airpod holder adds a delightful touch of feline personality to your tech.",
        image: airpodHolder,
        webPage: "https://amzn.to/3l4jzef",
    },
    {
        id: 45,
        productName: "Computer Mouse with RGB",
        description: "Go big and colorful, just like your code! Elevate your mouse game with vibrant RGB lighting and unleash your creativity.",
        image: gamingMouse,
        webPage: "https://amzn.to/3mEkTVn",
    },
    {
        id: 46,
        productName: "Robot Vacuum",
        description: "Don't let cleaning distract you from your coding endeavors. Let the robot do the dirty work while you focus on what truly matters.",
        image: robotVacuum,
        webPage: "https://amzn.to/3Fjz3Sy"
    },
    {
        id: 47,
        productName: "Smart Light Bulbs",
        description: "Alexa, it's party time! Set the mood with smart light bulbs that illuminate your coding den in vibrant hues.",
        image: smartLights,
        webPage: "https://amzn.to/3yvaeyX",
    },
    {
        id: 48,
        productName: "Vertical Laptop Stand",
        description: "When you're not busy debugging, let your laptop rest in style. A vertical laptop stand is both practical and visually pleasing.",
        image: vLaptopStand,
        webPage: "https://amzn.to/4277hCw",
    },
]